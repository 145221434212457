
  export const style={
    inpuStyle:{
      colorInput:"#2E63FB"
    },
    url:{
      urlClient:"https://the-bakery.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/the_bakery.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: null,
    },
    CurrencyName:{
      name:"Noz/Nozes"
    },
    teamName:{
      name:"The Bakery Brasil"
    },
    hasToken: false,
    hostHeader: "the-bakery"
  }
