const initialState = {
  menuList: []
};

export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MENU_LIST':
      return {
        ...state,
        menuList: action.menuList.sort(
          (a, b) => a.queue_position - b.queue_position
        ),
        menuListPagination: action.menuListPagination
      };
    case 'ADD_MENU_LIST':
      const addedMenuList = [...state.menuList, action.menuList];

      return {
        ...state,
        menuList: addedMenuList
      };
    case 'EDIT_MENU_LIST':
      const findItem = state.menuList.map((item) =>
        action.menuList.id === item.id
          ? { ...item, title: action.menuList.title }
          : item
      );

      return {
        ...state,
        menuList: findItem
      };
    case 'DELETE_MENU_LIST':
      const filterItem = state.menuList.filter(({ id }) => action.id !== id);

      return {
        ...state,
        menuList: filterItem
      };
    default:
      return state;
  }
};
